<!-- eslint-disable vue/max-attributes-per-line -->
<!-- eslint-disable quotes -->
<!-- eslint-disable quotes -->
<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo d-none d-md-inline-block">
        <!-- <vuexy-logo /> -->
        <h2 class="brand-text ml-1">
          bright<span class="text-primary"><b>Skills</b></span>
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            bright<span class="text-primary"><b>Skills</b></span>
          </b-card-title>
          <!--  <b-card-text class="mb-2">
            Please sign-in to your account and start the adventure
          </b-card-text> -->

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <b-form-group label="Phone Number*" label-for="number">
                <validation-provider
                  #default="{ errors }"
                  name="Phone Number"
                  rules="required"
                >
                  <b-form-input
                    :disabled="verify_status"
                    id="number"
                    v-model="form.number"
                    :state="errors.length > 0 ? false : null"
                    name="number"
                    placeholder="Enter Your Phone Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <div v-if="verify_status">
                <b-form-group
                  label="Verification Code*"
                  label-for="verification_code"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Verification Code"
                    rules="required"
                  >
                    <b-form-input
                      id="verification_code"
                      v-model="form.verification_code"
                      :state="errors.length > 0 ? false : null"
                      name="verification_code"
                      placeholder="Enter Your Verification Code"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <div v-if="user_status_check == false">
                  <b-form-group label="Full Name*" label-for="name">
                    <validation-provider
                      #default="{ errors }"
                      name="Full Name"
                      rules="required"
                    >
                      <b-form-input
                        id="name"
                        v-model="form.name"
                        :state="errors.length > 0 ? false : null"
                        name="name"
                        placeholder="Enter Your Full Name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group label="Email" label-for="email">
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      rules="email"
                    >
                      <b-form-input
                        id="email"
                        v-model="form.email"
                        :state="errors.length > 0 ? false : null"
                        name="email"
                        placeholder="Enter Your Email"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>

                <!-- forgot password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">Password</label>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="form.password"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">Confirm Password</label>
                    <b-link @click="Registration()">
                      <small>Resend Code</small>
                    </b-link>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="Confirm Password"
                    rules="required|confirmed:Password"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="password_confirmation"
                        v-model="form.password_confirmation"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="password_confirmation"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>

              <!-- submit buttons -->
              <b-button
                v-if="verify_status == false"
                type="submit"
                variant="primary"
                block
                :disabled="is_disable"
                @click="validationForm"
              >
                Next Step
              </b-button>
              <b-button
                v-if="verify_status"
                type="submit"
                variant="primary"
                block
                :disabled="is_disable"
                @click="verifyForm"
              >
                Next Step
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>Already have an account? </span>
            <b-link :to="{ name: 'login' }">
              <span>Sign in instead</span>
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from "bootstrap-vue";
import { required, email, confirmed, password } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      form: {
        name: "",
        number: "",
        email: "",
        password: "",
        password_confirmation: "",
        verification_code: "",
      },
      user_status_check: false,
      verify_status: false,
      is_disable: false,
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      // validation rulesimport store from '@/store/index'
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties, quotes
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    Registration() {
      this.is_disable = true;
      if (this.form.number == null && this.form.number == "") {
        return this.e("Phone Number Required :(");
      }
      axios
        .post("/affiliate/user/manage", this.form)
        .then((res) => {
          if (res.data.success) {
            this.verify_status = res.data.success;
            this.user_status_check = res.data.user_status;
            this.s("Verification Code Successfully Send");
            // this.$router.push("/verify/account?number=" + res.data.number);
          }
          this.is_disable = false;
        })
        // eslint-disable-next-line no-unused-vars
        .catch((e) => {
          if (e.response.status === 422) {
            if (e.response.data.errors["name"])
              this.e(e.response.data.errors["name"][0]);
            if (e.response.data.errors["number"])
              this.e(e.response.data.errors["number"][0]);
            if (e.response.data.errors["email"])
              this.e(e.response.data.errors["email"][0]);
            if (e.response.data.errors["password_confirmation"])
              this.e(e.response.data.errors["password_confirmation"][0]);
            this.is_disable = false;
          }
        });
    },

    Verify() {
      this.is_disable = true;
      axios
        .post("affiliate/user/verified", this.form)
        .then((res) => {
          if (res.data.success) {
            this.s("Your Account Successfully Updated");
            localStorage.setItem("token", res.data.data.token);
            localStorage.setItem("user_name", res.data.data.name);
            localStorage.setItem(
              "affiliate_token",
              res.data.data.affiliate_token
            );
            this.$store.dispatch("setToken", res.data.data);
            this.$router.push({
              name: "dashboard",
            });
          }
          this.is_disable = false;
        })
        // eslint-disable-next-line no-unused-vars
        .catch((e) => {
          if (e.response.status === 422) {
            if (e.response.data.errors["verification_code"])
              this.e(e.response.data.errors["verification_code"][0]);
            if (e.response.data.errors["name"])
              this.e(e.response.data.errors["name"][0]);
            if (e.response.data.errors["number"])
              this.e(e.response.data.errors["number"][0]);
            if (e.response.data.errors["email"])
              this.e(e.response.data.errors["email"][0]);
            if (e.response.data.errors["password_confirmation"])
              this.e(e.response.data.errors["password_confirmation"][0]);
            this.is_disable = false;
          }
          if (e.response.status === 401) {
            this.e("These credentials do not match our records.");
          }
          this.is_disable = false;
        });
    },
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.Registration();
        }
      });
    },

    verifyForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.Verify();
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
